var exports = {};

exports = function (hljs) {
  var LITERAL = {
    className: "literal",
    begin: "[\\+\\-]",
    relevance: 0
  };
  return {
    aliases: ["bf"],
    contains: [hljs.COMMENT("[^\\[\\]\\.,\\+\\-<> \r\n]", "[\\[\\]\\.,\\+\\-<> \r\n]", {
      returnEnd: true,
      relevance: 0
    }), {
      className: "title",
      begin: "[\\[\\]]",
      relevance: 0
    }, {
      className: "string",
      begin: "[\\.,]",
      relevance: 0
    }, {
      // this mode works as the only relevance counter
      begin: /\+\+|\-\-/,
      returnBegin: true,
      contains: [LITERAL]
    }, LITERAL]
  };
};

export default exports;